<template>
  <div class="m_chart" :style="style">
    <div class="main" :id="chartId"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
export default {
  props: {
    style: {
      type: Object,
      default: null
    },
    chartData: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      chartId: 'Chart' + parseInt(Math.random() * 10000),
      data: [
        { type: '分类一', value: 27 },
        { type: '分类二', value: 25 },
        { type: '分类三', value: 18 },
        { type: '分类四', value: 15 },
        { type: '分类五', value: 10 },
        { type: '其他', value: 5 }
      ]
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      var myChart = echarts.init(document.getElementById(this.chartId), 'dark')
      // 绘制图表
      myChart.setOption({
        title: {
          text: this.chartData.title,
          textStyle: {
            color: 'rgb(255,255,255)'
          },
          padding: [10, 0, 0, 30]
        },
        textStyle: {
          color: '#fff'
        },
        backgroundColor: 'rgba(0,0,0,0.0)',
        color: this.chartData.color ?? ['rgb(0,94,170)', 'rgb(0,152,257)', 'rgb(43,130,29)', 'rgb(232,124,37)', 'rgb(230,182,0)', 'rgb(193,46,52)'],
        tooltip: {
          trigger: 'item',
          formatter: '{b}：{c}个<br/>占比： {d}%'
        },
        series: [
          {
            name: '分时网点 TOP500 饱和状态',
            type: 'pie',
            radius: ['20%', '55%'],
            data: this.chartData.data
          }
        ]
      })
    }
  }
}
</script>

<style lang="less" scoped>
.m_chart {
  position: absolute;
  .main {
    height: 100%;
    width: 100%;
    border-radius: 10px;
    color: #fff;
    background-color: rgba(20, 87, 121, 0.5);
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.1), -2px -2px 3px rgba(0, 0, 0, 0.1);
  }
}
</style>
